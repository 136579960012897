var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-custom card-stretch"},[_c('div',{staticClass:"card-body pt-4"},[_c('div',{staticClass:"d-flex align-items-center mt-4"},[_c('div',{staticClass:"symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"},[_c('div',{staticClass:"symbol-label",style:({backgroundImage: ("url(/media/svg/avatars/" + (_vm.currentUser.avatar) + ")")})})]),_c('div',[_c('a',{staticClass:"font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"},[_vm._v(" "+_vm._s(_vm.currentUser.firstName)+" "+_vm._s(_vm.currentUser.lastName)+" ")]),_c('div',{staticClass:"text-muted"},[(_vm.currentUser.type === _vm.Roles.ADMIN)?_c('span',[_vm._v(_vm._s(_vm.$t('project.admin')))]):_vm._e(),(_vm.currentUser.type === _vm.Roles.EDITOR)?_c('span',[_vm._v(_vm._s(_vm.$t('project.editor')))]):_vm._e(),(_vm.currentUser.type === _vm.Roles.USER)?_c('span',[_vm._v(_vm._s(_vm.$t('project.member')))]):_vm._e()]),_c('div',{staticClass:"mt-2"},[_c('a',{staticClass:"text-muted text-hover-primary"},[_vm._v(" "+_vm._s(_vm.currentUser.email)+" ")])])])]),_c('div',{staticClass:"navi navi-bold navi-hover navi-active navi-link-rounded py-9"},[_c('div',{staticClass:"navi-item mb-2"},[_c('router-link',{attrs:{"to":"/profile/qrCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"navi-link py-4",class:{'active': isExactActive},staticStyle:{"cursor":"pointer"},attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon mr-2"},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/General/Expand-arrows.svg"}})],1)]),_c('span',{staticClass:"navi-text font-size-lg"},[_vm._v("QR Kodum")])])]}}])})],1),_c('div',{staticClass:"navi-item mb-2"},[_c('router-link',{attrs:{"to":"/profile/detail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"navi-link py-4",class:{'active': isExactActive},staticStyle:{"cursor":"pointer"},attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon mr-2"},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Design/Layers.svg"}})],1)]),_c('span',{staticClass:"navi-text font-size-lg"},[_vm._v(_vm._s(_vm.$t('project.profileOverview')))])])]}}])})],1),_c('div',{staticClass:"navi-item mb-2"},[_c('router-link',{attrs:{"to":"/profile/change-password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"navi-link py-4",class:{'active': isExactActive},staticStyle:{"cursor":"pointer"},attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon mr-2"},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Communication/Shield-user.svg"}})],1)]),_c('span',{staticClass:"navi-text font-size-lg"},[_vm._v(_vm._s(_vm.$t('project.changePassword')))])])]}}])})],1),_c('div',{staticClass:"navi-item mb-2"},[_c('router-link',{attrs:{"to":"/profile/book-history"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"navi-link py-4",class:{'active': isExactActive},staticStyle:{"cursor":"pointer"},attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon mr-2"},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Home/Book-open.svg"}})],1)]),_c('span',{staticClass:"navi-text font-size-lg"},[_vm._v(_vm._s(_vm.$t('project.bookHistory')))])])]}}])})],1),_c('div',{staticClass:"navi-item mb-2"},[_c('router-link',{attrs:{"to":"/profile/wish-list"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"navi-link py-4",class:{'active': isExactActive},staticStyle:{"cursor":"pointer"},attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"navi-icon mr-2"},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/General/Star.svg"}})],1)]),_c('span',{staticClass:"navi-text font-size-lg"},[_vm._v(_vm._s(_vm.$t('project.wishList')))])])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }